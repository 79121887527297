import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isPhone } from '../../services/deviceService';
import './FinalSteps.scss';
import TenantContext from '../../contexts/tenantContext';

const FinalSteps: FC = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useHistory();
  const tenant = useContext(TenantContext);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleNext = () => {
    if (activeStep === tenant.finalSteps.length - 1)
      history.push("/login");

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  console.log('FinalSteps tenant', activeStep, tenant.finalSteps)
  return (
    <div className="FinalSteps" data-testid="FinalSteps">
      <Grid container direction="row" alignItems="center" justifyContent="center">
        {!isPhone() && <Grid item xs={3} className="desktop"></Grid>}
        <Grid item xs={12} sm={5} className="message-container">
          <Box height="20vh"></Box>
          <Typography variant="h5" className="message"><span className="ft-35" dangerouslySetInnerHTML={{ __html: tenant.finalSteps[activeStep] || "" }} /> {activeStep === 1 && <span className="ft-35">You will be emailed your certificate when it is ready.</span>}</Typography>
          <Box height="20vh"></Box>
        </Grid>
        {!isPhone() && <Grid item xs={3} className="desktop"></Grid>}
      </Grid>
      <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end">
        <Grid item>
          {activeStep >= 2 &&
          <Button
            id='back-btn'
            style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
            className="green-submit-button back-button-v2"
            variant="contained"
            color="primary"
            onClick={handleBack}
          >
            Back
          </Button>}
          <Button
            id='next-btn'
            style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
            className="green-submit-button"
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {activeStep === tenant.finalSteps.length - 1 ? 'Finish' : 'I understand'}
          </Button>
        </Grid>
      </Grid>

    </div>
  );
}


export default FinalSteps;
