import React, { FC, useEffect, useState } from 'react';

interface Props {
  subtitles?: string;
  cues?: any;
}

const MultiSubtitles: FC<Props> = props => {

  const [subtitles, setSubtitles] = useState('');
  var index = 0;
  var timeouts = [] as any[];

  useEffect(() => {
    try{
      setSubtitles('');
      console.log('cues: ', props.cues)
      console.log('cues length: ', props.cues.length)
      console.log('subtitles: ', props.subtitles)

      if (timeouts && timeouts.length > 0) {
        timeouts.forEach(timeout => {
          clearTimeout(timeout);
        });
      }

      if (props.subtitles && props.subtitles !== '') {
        setSubtitles(props.subtitles);
      } else if (props.cues.length > 0) {
        console.log('index', index);
        console.log('selected cue', props.cues[index]);
        // setSubtitles(props.cues[index].text);
        console.log('start time', props.cues[index].startTime);
        
        timeouts.push(setTimeout(() => {
          showSubtitles(1000 * (props.cues[index].endTime - props.cues[index].startTime));
        }, 1000 * props.cues[index].startTime));
      }
    }
     catch(e)
      {
        console.log(e);
      }
    // eslint-disable-next-line
  },[props.cues, props.subtitles]);

  const showSubtitles = (subtitleTimeout: number) => {
    try
    {
      setSubtitles(props.cues[index].text);

      if (props.cues.length > index + 1) {

        // timeout to hide subtitles.
        timeouts.push(setTimeout(() => {
          setSubtitles('');
     
          const hideSubtitleTimeout = props.cues[index + 1].startTime - props.cues[index].endTime;
          index++;


          // timeout to show next subtitles.
          setTimeout(() => {


            showSubtitles(1000 * (props.cues[index].endTime - props.cues[index].startTime));

          }, 1000 * hideSubtitleTimeout);

        }, subtitleTimeout));

      }

    } catch (e)
    {
      console.log(e);
    }
  }

  return <div className="recording-subtitle">
    {subtitles && subtitles != "" && <span>{subtitles}</span>}
  </div>


}

export default MultiSubtitles;
