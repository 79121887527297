import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, Grid, LinearProgress, Step, StepLabel, Stepper } from '@material-ui/core';
import ReactPlayer from 'react-player';

import { Authorization } from '../../services/authorization';
import WebcamStreamCapture from '../WebcamStreamCapture/WebcamStreamCapture';
import './Recording.scss';
import { isPhone } from '../../services/deviceService';
import { WEB_SOCKET_URL, SERVER_URL, IS_DEV } from '../../config';
import ReactGA from "react-ga4";
import { isIOS, isIOS13, isMobileSafari } from 'react-device-detect';
import TenantContext from '../../contexts/tenantContext';
import { WebVTTParser } from 'webvtt-parser';

interface RecordingProps {
  next(): void;
}

const Recording: FC<RecordingProps> = props => {
  const history = useHistory();
  const [connectionLost, setConnectionLost] = useState(false);
  const [prevStep, setPrevStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [subStep, setSubStep] = React.useState(-1);
  const [repeatCount, setRepeatCount] = React.useState(0);

  const [isActorVideoPlaying, setActorVideoPlaying] = React.useState(false); /// Used to start actor video buffering and playing
  const [isVideoStarted, setIsVideoStarted] = React.useState(false); /// Used to show subtitles only when video is started.
  
  const [recordStarted, setRecordStarted] = useState(false);
  const [isRecording, setRecording] = React.useState(false);

  const [videoRecordingData, setVideoRecordingData] = React.useState(null as any);

  var [nextButtonDisabledState, setNextButtonDisabledState] = React.useState(true);

  const webSocket = React.useRef(null as any);

  const tenant = useContext(TenantContext);

  const [audioContext, setAudioContext] = useState(null as any);
  const [internalPlayer, setPlayer] = useState(null as any);
  var [source, setSource] = useState(null as any);
  const [lastUrl, setLastUrl] = useState("");

  const playerRef = (player: any) => {
    if (player && player.player && player.player.props.url !== lastUrl) {
      setLastUrl(player.player.props.url);
      setPlayer(player);
    }
  }

  useEffect(() => {
    try {
      ReactGA.event({
        category: 'Progress',
        action: 'Recording',
        label: `Step: ${activeStep.toString()}, video: ${tenant.recordingSteps[activeStep].videos[subStep]}`
      });
    }
    catch (error) {
      console.log('Google Analytics error.')
    }
    // eslint-disable-next-line
  }, [activeStep, subStep]);

  /* disabled back button */
  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    let dd = window as any;
    dd.history.pushState(null, null, window.location.pathname);
  }

  React.useEffect(() => {
    window.scroll(0, -4000);
    let dd = window as any;
    dd.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);
  /* disabled back button */

  /* landscape detection */
  const isHorizontal = () => {
    // Apple does not seem to have the window.screen api so we have to use deprecated window.orientation instead.
    if (window.orientation && typeof window.orientation === "number" && Math.abs(window.orientation) === 90) {
      return true;
    }
    if (window.screen.orientation && window.screen.orientation.type.includes('/^landscape-.+$/') === true) {
      return true;
    }
    return false;
  };

  const [horizontal, setHorizontal] = React.useState(isHorizontal());

  React.useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setHorizontal(isHorizontal());
    });
  }, []);
  /* landscape detection */


  const [username] = useState(Authorization.GetUsername() as string);

  const handleStart = (videoRecordingData: any) => {
    setRecordStarted(true);
    setVideoRecordingData(videoRecordingData)

    if (!webSocket?.current){
      webSocket.current = new WebSocket(`${WEB_SOCKET_URL}?email=${encodeURIComponent(username)}&videoId=${encodeURIComponent(videoRecordingData.videoId)}`);
      console.log('new websocket connection');

      webSocket.current.onclose = (e: any) => {
        console.log(`websocket close code: ${e.code} with reason :${JSON.stringify(e)}`)
        
        if (e.code !== 1000) {
          setRecording(false);
          setConnectionLost(true);
          console.log('connection lost', e);
        }
      }

      webSocket.current.onerror = (e: any) => {
        console.log(`websocket error: :${JSON.stringify(e)}`)
        setRecording(false);
        setConnectionLost(true);
        console.log('connection lost', e);
      }
    }
  }

  const postVideoChunk = async () => {
    setIsVideoStarted(true);
    
    if (webSocket.current.readyState == 1) {
      webSocket.current.send('test');
    }

    var chunkTimestamp = (Date.now() - videoRecordingData.timestamp);

    const url = SERVER_URL + "/video/chunk";

    const newVideoChunk = {
      videoId: videoRecordingData.videoId,
      questionUrl: tenant.recordingSteps[activeStep].videos[subStep],
      questionTime: chunkTimestamp,
      questionText: await loadSubtitles(`/subtitles/${tenant.tenantName}/recording/r${activeStep}-${subStep}.en.vtt`)
    }

    await axios.post(url, newVideoChunk, {
      headers: {
        Authorization: await Authorization.AuthToken()
      }
    });
  }

  const loadSubtitles = async (subtitlesUrl: string) : Promise<string> => {
    try {
      var response = await axios.get(subtitlesUrl);
      const parser = new WebVTTParser();
      const parsedCues = parser.parse(response.data, 'metadata').cues as any[];
      return parsedCues.map(cue => cue.text).join(' ');
    }
    catch (e) {
      console.log(e);
      return "";
    }
  }

  const handleRepeat = () => {
    setActorVideoPlaying(true);
    setRepeatCount(repeatCount + 1);
  }

  const handleNext = () => {
    setRepeatCount(0);

    if (prevStep <= 1) {
      setPrevStep(prevStep + 1);
      return;
    }
    if (activeStep === 0 && subStep === -1) {
      setNextButtonDisabledState(true);
      setRecording(true);
      setActorVideoPlaying(true);
    }
    //Increase sub step number.
    if (tenant.recordingSteps[activeStep].videos.length > subStep + 1) {
      setSubStep((prevSubStep) => prevSubStep + 1);
      if (videoRecordingData) {
        setActorVideoPlaying(true);
        setNextButtonDisabledState(true);
      }
      return;
      //Increase step number
    } else if (tenant.recordingSteps.length > activeStep + 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSubStep(0);
      if (videoRecordingData) {
        setActorVideoPlaying(true);
        setNextButtonDisabledState(true);
      }
      return;
    } else {
      setRecording(false);
      setNextButtonDisabledState(true);
      if (audioContext) {
        audioContext.close();
      }
    }
  };

  const handleFinished = (videoFolder: string) => {
    console.log('finished');
    webSocket.current.close(1000);
    localStorage.setItem('videoFolder', videoFolder)
    props.next();
  }

  const handleRecodingInterrupted = () => {
    console.log('interrupted');
    setConnectionLost(true);
    webSocket.current.close(3001, 'video recording interrupted.');
  }


  // Safari sound fix.
  const mixAudio = () => {
    if (isMobileSafari || isIOS || isIOS13) {
      if (internalPlayer != null) {
        if (!source) {

          var context = new AudioContext()
          setAudioContext(context);
          var player = internalPlayer!.getInternalPlayer();

          var alexSource = context.createMediaElementSource(player);
          setSource(alexSource);
          var gain = context.createGain();

            gain.gain.value = 8;

          alexSource.connect(gain);
          gain.connect(context.destination);

        }
      }
    }
  }

  const renderRecordDescription = () => {
    if (recordStarted) {
      return (
        <div>
          <div className={`test-audio-description ${!isActorVideoPlaying ? "invisible-alex" : "alex-video-shadow"}`}>
            <ReactPlayer
              ref={playerRef}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "anonymous"
                  }
                }
              }}
              controls={false}
              playing={isActorVideoPlaying}
              pip={false}
              onReady={() => { mixAudio(); setNextButtonDisabledState(true); setActorVideoPlaying(true); }}
              volume={1.0}
              playsinline
              url={tenant.recordingSteps[activeStep].videos[subStep]}
              onStart={postVideoChunk}
              onEnded={() => {
                setNextButtonDisabledState(false);
                setActorVideoPlaying(false);
              }} />
          </div>
        </div>
      );
    }
    return <div />;
  }

  if (prevStep <= 1) {
    return (
      <div className="Recording">
        <div className="message-content ft-35">
          {prevStep === 0 && <div>
            <strong>You are ready to start. </strong>
            It should take 10-20 minutes. You must
            complete your session in one go. If you
            pause or cancel your session you will
            have to start again from Device Check.
          </div>}
          {prevStep === 1 && <div>
            <strong>You should make your call alone. </strong>
            You can have a medical professional or
            your lawyer present if they say it is
            necessary. Everyone in the room will be
            asked to identify themselves during the
            session.
          </div>}
        </div>
        <Button 
          id="next-btn"
          style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
          className="green-submit-button"
          variant="contained"
          color="primary"
          onClick={handleNext}
        >
          Ok
        </Button>
      </div>
    );
  }

  let className = 'RecordingVideo';
  if (isPhone() && horizontal) {
    className += ' Horizontal';
  }

  return (<div data-testid="Recording" className="Recording">
    {isPhone() ? <LinearProgress className='phone-tutorial-progress' variant="determinate" value={(activeStep + 1) * 12.5} /> :
      <Stepper activeStep={activeStep} className="tutorial-stepper">
        {tenant.recordingSteps.map((label, index) => {
          const stepProps = {} as any;
          const labelProps = {} as any;
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}></StepLabel>
            </Step>
          );
        })}
      </Stepper>}

    <Grid container className="justify-center">
      <div>
        <div className={className}>
          <div>
            {renderRecordDescription()}
            <WebcamStreamCapture
              saveVideoRecording={true}
              username={username} 
              isRecordingStarted={isRecording} 
              onMouted={() => { setNextButtonDisabledState(false); }}
              onStart={handleStart}
              onFinished={handleFinished}
              onInterrupted={handleRecodingInterrupted}
              subtitlesUrl={isActorVideoPlaying && isVideoStarted ? `/subtitles/${tenant.tenantName}/recording/r${activeStep}-${subStep}.en.vtt` : ''}
              isActorVisible={isActorVideoPlaying && isVideoStarted}
            />
          </div>
        </div>
        {repeatCount < 5 && !isActorVideoPlaying && !(activeStep === 0 && subStep <= 0) && <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
          id="btn-repeat"
          className="green-submit-button repeat-button"
          variant="contained"
          color="primary"
          onClick={handleRepeat}
          disabled={nextButtonDisabledState}
        >
          Repeat the question
        </Button>
        }
        {(!isActorVideoPlaying || IS_DEV === "true") &&
          <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
            id="next-btn"
            className="green-submit-button"
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={nextButtonDisabledState && !(IS_DEV === "true")}
          >
            {(activeStep === 0 && subStep <= 0) ? 'Start' : `Next ${IS_DEV === "true" ? " (DEV)" : ""}`}
          </Button>
        }
      </div>
    </Grid>
    <Dialog open={connectionLost} className="LogoutPopup update-success-dialog">
      <DialogTitle>
        Connection interrupted
      </DialogTitle>
      <div className="btn-row" style={{ display: " flex", alignItems: 'center', justifyContent: 'center' }}>
        <Button
          className="save-button"
          variant="contained"
          color="primary"
          onClick={() => history.push('/userAccount')}
        >
          Next
        </Button>
      </div>
    </Dialog>
  </div>
  );
}

export default Recording;
