import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Authorization } from "../../services/authorization";
import './ForgotPassword.scss';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { SERVER_URL } from "../../config";

const ForgotPassword = () => {
  const [clicked, setClicked] = useState(false);
  const [email, setEmail] = useState('');
  const history = useHistory();

  const resetPassword = async () => {
    if (clicked) {
      return;
    }
    setClicked(true);

    var passwordIsReset = false;

    try{
      await Authorization.ForgotPassword(email);
      passwordIsReset = true;
    } catch (err){
      if (err === "UserNotFoundException"){
        var emailExists = await axios.get(SERVER_URL + "/useremail", { params: { email: email } });
  
        if (emailExists && emailExists.data){
          try{
            await Authorization.Register(email, "T4$" + uuidv4(), email);
            await Authorization.ForgotPassword(email);
            passwordIsReset = true;
          } catch(e){
            console.error(e);
            alert("Please try again later.")
          }
        }
      }
    }

    if (passwordIsReset)
      history.push("/confirmPassword?username=" + encodeURIComponent(email));

    setTimeout(() => {
      setClicked(false);
    }, 100);
  }

  return (
    <div className="ForgotPassword">
      <div className="ft-22">
        <div className="title ft-25"><strong>Forgot password?</strong></div>
        <div>Enter email to reset password. We will send a link to set new password</div>
        <div className="email-container">
          <TextField id="standard-full-width"
            className="base-field"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputLabelProps={{ shrink: true }} label="Email" placeholder="enter your email" variant="outlined" />
        </div>
        <Button
          id='reset-password-btn'
          className="save-button"
          variant="contained"
          color="primary"
          onClick={() => resetPassword()}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
}

export default ForgotPassword;
