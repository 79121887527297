import React, { useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import './DownloadCertificate.scss';
import { SERVER_URL } from '../../config';
import { Authorization } from '../../services/authorization';
import { downloadCertificate, downloadMentalCapacityCertificate } from '../../services/certificateService';
import { Error } from '@material-ui/icons';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import { Certificate } from '../../models/cerfiticate';
import { ProcessingStatus, RecordingStatus } from '../../models/recording';



const DownloadCertificate: React.FC = () => {
  const [certificates, setCertificates] = React.useState([] as Certificate[]);

  const history = useHistory();

  const getCertificates = async () => {
    const url = SERVER_URL + "/certificates";

    const res = await axios.get(url, {
      headers: {
        Authorization: await Authorization.AuthToken()
      }
    });
    if (res && res.status === 200 && res.data && res.data.Items) {
      setCertificates(res.data.Items)
    } else {
      console.log('can`t get certificates');
    }
  }

  useEffect(() => {getCertificates()}, []);

  const renderField = (certificate: Certificate, i: number) => {
    var link = <div className="certificateLabel  ft-22"> Processing <CircularProgress style={{marginLeft:'5px'}}/></div>;
    // var mentalCapacityCertificateLink = null;
    //recordingStatus: 1, // 1 - Started, 2 - Finished, 3 - Interrupted.
    //processingStatus: 1, // 1 - Not Started, 2 - Started, 3 - Completed, 4 - Failed

    if (certificate.processingStatus === ProcessingStatus.Completed) {
      link = <div className="link underline ft-22" onClick={() => downloadCertificate(certificate.id)}>Certificate <ArrowDownwardOutlinedIcon fontSize="large"/></div>
    }

    //TODO new processing status should be added for Mental capacity certificate.
    // if (certificate.processingStatus === ProcessingStatus.Completed && certificate.recordingStatus === RecordingStatus.Finished) {
    //   mentalCapacityCertificateLink = <div className="link underline ft-22" onClick={() => downloadMentalCapacityCertificate(certificate.id)}>Capacity Report <ArrowDownwardOutlinedIcon fontSize="large"/></div>
    // }

    if (certificate.recordingStatus === RecordingStatus.Interrupted){
      link = <div className="certificateLabel ft-22 error" onClick={() => downloadCertificate(certificate.id)}> Interrupted<Error color='error' fontSize="large"/> </div>
    }

    if (certificate.processingStatus === ProcessingStatus.Failed) {
      link = <div className="certificateLabel ft-22 error" onClick={() => downloadCertificate(certificate.id)}> Interrupted<Error color='error' fontSize="large"/> </div>
    }

    return (
      <div className="certificate-container" key={i}>
        <TextField id="outlined-search" required className="base-field"
          name="town"
          disabled={true}
          value={certificate.tenantName === "default" 
          
          ? "Will " + new Date(certificate.timestamp).toLocaleString('en-GB', {
              day: 'numeric', // numeric, 2-digit
              year: 'numeric', // numeric, 2-digit
              month: 'numeric', // numeric, 2-digit, long, short, narrow
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            })
          : certificate.tenantName + " " + new Date(certificate.timestamp).toLocaleString('en-GB', {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'numeric', // numeric, 2-digit, long, short, narrow
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          }) + ' ' + certificate.id.split('/')[1] + '.pdf'} 
          InputLabelProps={{ shrink: true }} placeholder="pdf date and name" variant="outlined" />
        <div>
          {link}
          {/* {mentalCapacityCertificateLink && mentalCapacityCertificateLink} */}
        </div>
      </div>
    );
  }

  return (
    <div className="DoanloadCertificate">
      <div>
        <p className="ft-25 upper-text semi-bold">On this page you can download certificates of your completed recordings. We recommend you print your certificate and keep it with your will.</p>
        <div className="certificates">
          {certificates.map(renderField)}
        </div>
      </div>
      <Button 
        id='back-btn'
        style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/userAccount')}
      >
        Back
      </Button>
    </div>
  )
}

export default DownloadCertificate;
