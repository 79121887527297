import React, { FC, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Grid, LinearProgress, Step, StepLabel, Stepper } from '@material-ui/core';
import ReactGA from "react-ga4";

import './AccountCreation.scss';
import { isPhone } from '../../services/deviceService';
import DeviceChecks from '../DeviceChecks/DeviceChecks';
import ScreenChecks from '../ScreenChecks/ScreenChecks';
import UserDetails from '../UserDetails/UserDetails';
import { Authorization } from '../../services/authorization';

enum ActiveStep {
  DeviceCheck = 0,
  ScreenCheck,
  FillForm,
}

enum StepState {
  Message,
  Component
}

interface AccountCreationProps {
  next(): void
}

export interface IGeolocation {
  latitude: number;
  longitude: number;
}

const AccountCreation: FC<AccountCreationProps> = props => {
  const deviceCheckRef = useRef(null);
  const screenCheckRef = useRef(null);
  const userDetailsRef = useRef(null);

  const [activeStep, setActiveStep] = React.useState(ActiveStep.DeviceCheck);
  const [nextButtonSubmitting, setNextButtonSubmitting] = React.useState(false);
  const [nextButtonDisabledState, setNextButtonDisabledState] = React.useState(false);
  const [activeStepState, setActiveStepState] = React.useState(StepState.Message);

  // eslint-disable-next-line
  const [geolocation, setGeolocation] = React.useState({ latitude: 0, longitude: 0 } as IGeolocation);

  const checkUser = async () => {
    const username = Authorization.GetUsername();
    if (username) {
      if (activeStep < 2) {
        setActiveStep(ActiveStep.ScreenCheck);
      }
    }
  }

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line
  }, [activeStep]);

  const stepsContent = [
    {
      message: "<span class='bold'>Tutorial Complete.</span> Now please create your Capacity Vault account. Please enter your details and follow the prompts.",
      component: <DeviceChecks ref={deviceCheckRef} setGeolocation={setGeolocation} moveNext={() => nextStep()} setNextButtonDisabledState={setNextButtonDisabledState} />
    },
    {
      message: "<span class='bold'>Great, that worked.</span> Now we will give you a short screen test. This is to check we can see your face in the front-facing camera and hear you.",
      component: <ScreenChecks ref={screenCheckRef} setNextButtonDisabledState={setNextButtonDisabledState} />
    },
    {
      message: "<span class='bold'>Camera checked.</span> Now please create your Capacity Vault account. Please enter your details and follow the prompts.",
      component: <UserDetails geolocation={geolocation} setSubmitting={setNextButtonSubmitting} ref={userDetailsRef} />
    }
  ]

  function getStepContent(step: number) {
    return stepsContent[Math.abs(step)];
  }

  function getSteps() {
    return ['Device check', 'Screen check', 'Fill the form'];
  }

  const steps = getSteps();

  useEffect(() => {
    ReactGA.event({
      category: 'Progress',
      action: 'Account Creation Step',
      label: steps[activeStep]
    });
    // eslint-disable-next-line
  }, [activeStep]);

  const handleNext = async () => {

    // Disable button for screen checks recording.
    if (activeStep === ActiveStep.ScreenCheck && activeStepState === StepState.Message) {
      setNextButtonDisabledState(true);
    }

    if (userDetailsRef && userDetailsRef.current) {
      const current = userDetailsRef?.current as any;
      const newUser = await current.next();
      if (!newUser)
        return;
    }

    if (deviceCheckRef && deviceCheckRef.current) {
      const current = deviceCheckRef?.current as any;
      const finish = await current.next();
      if (!finish) {
        return;
      }
    }

    if (screenCheckRef && screenCheckRef.current) {
      const current = screenCheckRef?.current as any;
      const finish = await current.next();
      if (!finish) {
        return;
      }
    }

    if (activeStepState === StepState.Component && activeStep === steps.length - 1){
      props.next();
      return;
    }

    if (activeStepState === StepState.Component) {
      nextStep();
      setActiveStepState(() => getStepContent(activeStep + 1)?.message
        ? StepState.Message
        : StepState.Component);
    }
    else
      setActiveStepState(() => StepState.Component);
  };

  return (<div className={`AccountCreation AccountPage-${activeStep} ActiveStep-${activeStepState}`} data-testid="AccountCreation">
    {isPhone() ? <div>
      <LinearProgress className='phone-tutorial-progress' variant="determinate" value={(activeStep + 1) * 20} />
    </div> :
      <Stepper activeStep={activeStep}>
        {steps.map(label => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>}

    {activeStepState === StepState.Message &&
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid>
          {!isPhone() && <Box height="20vh"></Box>}
          <div className="message-content" dangerouslySetInnerHTML={{ __html: getStepContent(activeStep).message }}></div>
          {!isPhone() && <Box height="20vh"></Box>}
        </Grid>
      </Grid>
    }
    {activeStepState === StepState.Component && activeStep === ActiveStep.ScreenCheck &&
      <div>
        <Grid container className="justify-center">
          <div>
            {getStepContent(activeStep).component}
          </div>
        </Grid>
      </div>
    }
    {activeStepState === StepState.Component && activeStep !== ActiveStep.ScreenCheck &&
      <Grid container>
        {getStepContent(activeStep).component}
      </Grid>
    }

    <Button 
      id="next-btn"
      style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
      variant="contained"
      color="primary"
      disabled={nextButtonDisabledState}
      className={`green-submit-button ${activeStep === ActiveStep.ScreenCheck && activeStepState === StepState.Component && 'video-button'}`}
      onClick={() => handleNext()}
    >
      {activeStepState === StepState.Component
        ? <span>Next {nextButtonSubmitting ? <CircularProgress /> : ''}</span>
        : 'I understand'}
    </Button>
  </div>);
};

export default AccountCreation;
