import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SetRecordingToken } from "../../services/localStorage/localstorage";
import { checkRecordingToken, getAffiliateById } from "../../services/axios/requests";
import { useAppDispatch } from "../../app/hooks";
import { saveAffiliate } from "../../features/affiliate/affiliateSlice";

interface Params {
  token: string;
}

const RecordingToken = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useParams<Params>();

  const [tokenInvalid, setInvalid] = useState(false);

  const checkToken = async () => {
    const {token} = params;
    const res = await checkRecordingToken(token);
    if (res && res.status === 200 && res.data) {
      // move to video recording 
      SetRecordingToken(
        token, 
        res.data.email,
        res.data.fullName,
        res.data.affiliateId
      );

      if (res?.data?.affiliateId) {
        const affiliate = await getAffiliateById(res.data.affiliateId);
        dispatch(saveAffiliate(affiliate.data));
      }

      history.push('/');
    } else {
      setInvalid(true);
    }
  }

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="RecordingToken">
      <div style={{'marginTop': '40px'}}>
        {tokenInvalid ? "Token is not valid or expired" : "Wait a minute we are checking if token is valid"}
      </div>
    </div>
  );
};

export default RecordingToken;
