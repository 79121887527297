import React, { useEffect } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { postcodeValidator } from 'postcode-validator';

import './SetUserDetails.scss';
import { useAppSelector } from '../../app/hooks';
import { selectAffiliate } from '../../features/affiliate/affiliateSlice';
import { updateUser } from '../../services/axios/requests';
import { GetRecordingToken } from '../../services/localStorage/localstorage';

interface UserDetails {
  address1: string;
  address2: string;
  county: string;
  dateOfBirth: Date | null;
  postcode: string;
  town: string;
  affiliateId: string | null;
}

interface SetUserDetailsProps {
  next(): void;
}

const SetUserDetails = (props: SetUserDetailsProps) => {
  const [user, setUser] = React.useState({ dateOfBirth: null } as UserDetails);
  var [validationErrors, setValidationErrors] = React.useState({} as any);
  const [nextButtonSubmitting, setNextButtonSubmitting] = React.useState(false);

  const affiliate = useAppSelector(selectAffiliate);

  const setAffiliate = async () => {
    if (affiliate)
      setUser({ ...user, affiliateId: affiliate.affiliateId });
  }

  useEffect(() => {
    setAffiliate();

    if (sessionStorage.getItem("iframe-name")) {
      let date2 = null;
      let date = sessionStorage.getItem("iframe-dateOfBirth");
      if (date) {
        date2 = new Date(date);
      }
      setUser({
        ...user,
        dateOfBirth: date2,
        address1: sessionStorage.getItem("iframe-address1") || "",
        address2: sessionStorage.getItem("iframe-address2") || "",
        town: sessionStorage.getItem("iframe-town") || "",
        postcode: sessionStorage.getItem("iframe-postcode") || "",
        county: sessionStorage.getItem("iframe-county") || "",
      })

      sessionStorage.removeItem("iframe-email");
      sessionStorage.removeItem("iframe-name");
    }
    // eslint-disable-next-line
  }, []);

  const handleDateChange = (date: any) => {
    user.dateOfBirth = date;
    setUser({ ...user });
    validate();
  };

  const handleUserChange = (event: any) => {
    var value = event.target.name === "postcode"
      ? event.target.value.toUpperCase()
      : event.target.value;

    const column = event.target.name as keyof UserDetails;

    if (
      column === 'address1' || column === 'dateOfBirth'
      || column === 'address2' || column === 'town'
      || column === 'county' || column === 'postcode'
      || column === 'affiliateId'
    ) {
      user[column] = value;
    }

    setUser({ ...user });
    validate();
  }

  const validate = () => {

    validationErrors = {};

    if (!user.dateOfBirth)
      validationErrors.dateOfBirth = "Date of birth is required";

    if (user.dateOfBirth) {
      const timeOfBirth = new Date().getTime() - user.dateOfBirth.getTime();
      if (isNaN(timeOfBirth)) {
        validationErrors.dateOfBirth = 'Date of birth is invalid.';
      }
      if (timeOfBirth < 18 * 31556952000) {
        validationErrors.dateOfBirth = 'Date of birth should be 18 or above';
      }
    }

    if (!user.address1)
      validationErrors.address1 = "Address is required";

    if (!user.town)
      validationErrors.town = "Town is required";

    if (!user.postcode) {
      validationErrors.postcode = "Postcode is required";
    } else {
      var isValid = postcodeValidator(user.postcode, 'GB');
      if (!isValid) {
        validationErrors.postcode = "Postcode is not valid";
      }
    }

    setValidationErrors({ ...validationErrors });
    return validationErrors;
  }

  const handleSubmit = async () => {
    setNextButtonSubmitting(true);

    const errors = validate();

    if (errors.postcode || errors.address1 || errors.town || errors.dateOfBirth) {
      setNextButtonSubmitting(false);
      return;
    }

    const saveData = {
      address1: user.address1,
      address2: user.address2,
      county: user.county,
      dateOfBirth: user.dateOfBirth,
      postcode: user.postcode,
      town: user.town,
      affiliateId: user.affiliateId,
      affiliateToken: GetRecordingToken()
    }

    const res = await updateUser(saveData);

    setNextButtonSubmitting(false);
    if (res && res.status === 200) {
      props.next();
    }
  };

  useEffect(() => {
    var dd = document.getElementById('date-picker-inline-label');
    if (dd) {
      dd.remove();
    }
  }, [])

  return (
    <form>
      <div className="UserDetails UpdateUserDetails SetUserDetails" data-testid="UserDetails">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="set-details-box">
            <div className="column">
              <div>
                <div className="MuiFormControl-root">
                  <div className="input-label">Date of Birth</div>
                  <KeyboardDatePicker
                    name="dateOfBirth"
                    inputVariant="outlined"
                    disableToolbar
                    variant="inline"
                    className="date-of-birth"
                    format="dd/MM/yyyy"
                    margin="normal"
                    placeholder="DD/MM/YYYY"
                    id="date-picker-inline"
                    label="Date of birth"
                    value={user.dateOfBirth}
                    helperText={validationErrors.dateOfBirth}
                    error={validationErrors.dateOfBirth}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }} />
                </div>
                <TextField id="outlined-search"
                  required
                  name="address1"
                  value={user.address1}
                  helperText={validationErrors.address1}
                  error={validationErrors.address1}
                  onChange={handleUserChange}
                  InputLabelProps={{ shrink: true }}
                  label="Building and street"
                  className="line-address"
                  placeholder="Enter line address 1"
                  variant="outlined" />
                <TextField
                  name="address2"
                  value={user.address2}
                  onChange={handleUserChange}
                  className="line-address second-line-address"
                  id="outlined-search"
                  placeholder="Enter line address 2"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined" />
              </div>
            </div>
            <div className="column">
              <div>
                <TextField id="outlined-search" required
                  name="town"
                  value={user.town}
                  helperText={validationErrors.town}
                  error={validationErrors.town}
                  onChange={handleUserChange}
                  InputLabelProps={{ shrink: true }} label="Town or city" placeholder="Enter your town or city" variant="outlined" />
                <TextField id="outlined-search"
                  name="county"
                  value={user.county}
                  onChange={handleUserChange}
                  InputLabelProps={{ shrink: true }} label="County" placeholder="Enter your country" variant="outlined" />
                <TextField id="outlined-search"
                  name="postcode"
                  value={user.postcode}
                  helperText={validationErrors.postcode}
                  error={validationErrors.postcode}
                  onChange={handleUserChange}
                  required InputLabelProps={{ shrink: true }} label="Postcode" placeholder="Enter your zip code" variant="outlined" />
                
              </div>
            </div>
          </div>
          <Button 
            id='next-btn'
            style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
            className="green-submit-button save-user-details"
            variant="contained"
            disabled={nextButtonSubmitting}
            color="primary"
            onClick={handleSubmit}
          >
            {nextButtonSubmitting ? <CircularProgress /> : 'Save'}
          </Button>
        </MuiPickersUtilsProvider>
      </div>
    </form >
  );
};

export default SetUserDetails;
