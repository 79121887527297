import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Authorization } from '../../services/authorization';
import { useHistory } from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import './Header.scss';
import PhoneMenu from './PhoneMenu';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSignIn, signOut, signIn } from '../../features/signIn/signInSlice';
import { selectMainStep } from '../../features/mainStep/mainStepSlice';
import { MainStepEnum } from '../MainSteps/MainSteps';
import { LANDING_URL } from '../../config';

const Header: React.FC<any> = (props: any) => {
  const domain = LANDING_URL;
  const dispatch = useAppDispatch();

  const mainStep = useAppSelector(selectMainStep);
  const signedIn = useAppSelector(selectSignIn);
  const [loggedOut, setPopup] = useState(false);

  const history = useHistory();
  const isLoginPage = history.location.pathname === '/login';


  const handleClose = () => {
    setPopup(false);
  }

  if (Authorization.GetUsername()) {
    if (!signedIn)
      dispatch(signIn());
  }

  const handleSignIn = async () => {
    if (signedIn) {
      await Authorization.SignOut();
      dispatch(signOut());
      if (mainStep === MainStepEnum.RecordingStep) {
        setPopup(true);
      }
    }
    history.push("/login");
  }

  return (
    <div className="Header" data-testid="Header">

      <a className="logo bold" href={domain}>
        <img src="/logo-wide.png" alt="logo" />
      </a>
      <div className="right-menu desktop">
        {signedIn && <a href={`/userAccount`}>MY ACCOUNT</a>}
        <a href={`${domain}/how-it-works`}>HOW IT WORKS</a>
        <a href={`${domain}/testamentary-capacity`}>TESTAMENTARY CAPACITY</a>
        <a href={`${domain}/about-us`}>ABOUT US</a>
        {!isLoginPage &&
        <Button
          id='sign-btn'
          className="log-in-btn ft-20"
          variant="contained"
          color="primary"
          onClick={handleSignIn}
        >
          {signedIn ? "Log Out" : "Log In"}
        </Button>}
      </div>
      <PhoneMenu signedIn={signedIn} domain={domain} handleSignIn={handleSignIn} />
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" className="LogoutPopup" open={loggedOut}>
        <DialogTitle className="ft-35" id="simple-dialog-title">
          You have left CV before completing your recording – to continue please log back in and follow the instructions on screen. All recordings must be completed in a single session. This should take 20 minutes to half an hour
        </DialogTitle>
      </Dialog>
    </div>
  )
};

export default Header;
