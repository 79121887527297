export enum RecordingStatus
{
    Started = '1',
    Finished = '2',
    Interrupted = 3
}

export enum ProcessingStatus
{
    NotStarted = 1,
    Started = '2',
    Completed = 3,
    Failed
}