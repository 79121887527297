import React, { useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import './UpdateDetails.scss';
import UpdateUserDetails from '../UserDetails/UpdateUserDetails';
import CurrentUserContext from '../../contexts/currentUserContext';

const UpdateDetails: React.FC = () => {
  const history = useHistory();

  const currentUser = useContext(CurrentUserContext);

  return (
    <div className="UserDetails UpdateUserDetailsPage">
      {currentUser?.User && <UpdateUserDetails user={currentUser.User} />}
      <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/userAccount')}
      >
        Back
      </Button>
    </div>
  )
}

export default UpdateDetails;
