import React, { FC, useContext, useEffect } from 'react';

import AWS from 'aws-sdk';
import { CircularProgress } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { IDENTITY_POOL_ID, IDENTITY_POOL_LOGIN, REGION, SERVER_URL, VIDEO_BUCKET } from '../../config';
import axios from 'axios';
import { isMobile, mobileModel, mobileVendor, browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import './WebcamStreamCapture.scss';
import RecSign from '../baseComponents/RecSign';
import { isPhone } from '../../services/deviceService';
import { Authorization } from '../../services/authorization';
import { WebVTTParser } from 'webvtt-parser';
import TenantContext from '../../contexts/tenantContext';
import MultiSubtitles from '../Recording/MultiSubtitles';
import { useAppSelector } from '../../app/hooks';
import { selectAffiliate } from '../../features/affiliate/affiliateSlice';
import { combineVideo } from '../../services/axios/requests';
import CurrentUserContext from '../../contexts/currentUserContext';

interface VideoData {
  videoId: string;
  email: string;
  folder: string;
  name: string;
  timestamp: number;
  os: string;
  browser: string;
  deviceDescription: string;
  tenantId: string;
  affiliateId: string;
}



interface WebcamStreamCaptureProps {
  isRecordingStarted: boolean;
  onFinished?(videoFolder: string): void;
  onInterrupted?(videoId: string): void;
  onMouted?(): void;
  onRecordingStop?(blob: Blob): void;
  onStart?(videoData: VideoData): void;
  saveVideoRecording?: boolean;
  username?: string,
  subtitlesUrl?: string,
  isActorVisible?: boolean;
  isDemo?: boolean;
  screenCheck?: boolean;
}

const WebcamStreamCapture: FC<WebcamStreamCaptureProps> = props => {
  const [mediaStream, setMediaStream] = React.useState<MediaStream>(null as any);
  const [pendingUploads, setPendingUploads] = React.useState(0);
  const [videoUniqueFolder] = React.useState(uuidv4());
  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const pendingUploadsRef = React.useRef(0);
  const webcamPreviewRef = React.useRef<HTMLVideoElement | null>(null);
  const [cues, setCues] = React.useState([] as any[]);
  const [processCuesToSave, setProcessCuesToSave] = React.useState([] as any[]);
  var [uploadPartNumber, setUploadPartNumber] = React.useState(0);
  var [waitingForUpload, setWaitingForUpload] = React.useState(false);
  // used to calculate at which time the recording started, to calculate the offset for the subtitles.
  const tenant  = useContext(TenantContext);
  const currentUser = useContext(CurrentUserContext);
  const [awsCredentialsAreRefreshed, setAwsCredentialsAreRefreshed] = React.useState(false);
  const [videoRecordingStartedTime, setVideoRecordingStartedTime] = React.useState(0);
  
  const affiliate = useAppSelector(selectAffiliate);

  var uploadId = 1;

  useEffect(() => {
      init();

      return(() => {
        if (mediaStream){
          stopCamera();
        }
      })
      // eslint-disable-next-line
  }, [])

  // Mute user audio while actor is asking question.
  useEffect(() => {
    if (!isMobile && mediaStream)
      mediaStream.getAudioTracks().forEach(function(track) {
        track.enabled = !props.isActorVisible;
      });
      // eslint-disable-next-line
  }, [props.isActorVisible])

  useEffect(() => {
    if (props.isRecordingStarted) {
      
      startRecording();
      
      console.log('recording start time:', webcamPreviewRef.current?.currentTime);

    } else if (!props.isRecordingStarted && mediaRecorderRef?.current?.state === "recording") {
      mediaRecorderRef.current.stop();
    }
    // eslint-disable-next-line
  }, [props.isRecordingStarted])

  const init = async () => {
    try {
      console.log('init webcam');

      let videoConstraints: MediaStreamConstraints["video"] = {
        width: 1280,
        height: 720,
        facingMode: "user",
        frameRate: 30
      };

      const audioConstraints: MediaStreamConstraints["audio"] = {
        autoGainControl: false,
        echoCancellation: true,
        noiseSuppression: true,
      };
      
      if (props.isDemo) {
        videoConstraints = {
          width: 500,
          height: 1280,
          facingMode: "user",
          frameRate: 30
        };
      }

      if (isPhone()) {
        videoConstraints = {
          width: 960,
          height: 720,
          facingMode: "user",
          frameRate: 30
        };
      }

      const constraints: MediaStreamConstraints = {
        audio: audioConstraints,
        video: videoConstraints
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      // console.log('getUserMedia() got stream:', stream);
      setMediaStream(stream);
      if (webcamPreviewRef.current){
        webcamPreviewRef.current.srcObject = stream;
      }
    } catch (e) {
      console.error('navigator.getUserMedia error:', e);
    }
  }

  const updateAWSCredentials = async () => {
    if (!awsCredentialsAreRefreshed) {
      await Authorization.RefreshToken();
      var authToken = await Authorization.AuthToken();

      var awsLogins = {} as any;
      awsLogins[IDENTITY_POOL_LOGIN] = authToken

      AWS.config.update({
        region: REGION,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IDENTITY_POOL_ID,
          Logins: awsLogins
        })
      });

      setAwsCredentialsAreRefreshed(true);
    }
  }

  const startRecording = async () => {
    if (!props.isDemo && !props.screenCheck)
      await updateAWSCredentials();

    uploadPartNumber = 1;
    setUploadPartNumber(uploadPartNumber);

    try {
      mediaRecorderRef.current = new MediaRecorder(mediaStream, {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000
      });
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      return;
    }

    console.log('Created MediaRecorder', mediaRecorderRef);

    mediaRecorderRef.current.onstop = handleStopCaptureClick;

    mediaRecorderRef.current.ondataavailable = handleDataAvailable;
    mediaRecorderRef.current.start(props.saveVideoRecording ? 5000 : 99999999999);
    console.log('MediaRecorder started', mediaRecorderRef);

    if (props.saveVideoRecording && !props.isDemo) {

      var url = SERVER_URL + "/video/started";

      const newVideo = {
        videoId: props.username + '/' + videoUniqueFolder,
        email: props.username,
        folder: videoUniqueFolder,
        name: videoUniqueFolder,
        timestamp: Date.now(),
        os: osName + ' ' + osVersion,
        browser: browserName + ' ' + browserVersion,
        deviceDescription: isMobile ? mobileVendor + ' ' + mobileModel : mediaStream?.getVideoTracks()[0]?.label,
        tenantId: tenant?.tenantId ?? '',
        affiliateId: affiliate?.affiliateId ?? null
      } as VideoData;

      await axios.post(url, newVideo, {
        headers: {
          Authorization: await Authorization.AuthToken()
        }
      });

      if (props.onStart)
        props.onStart(newVideo);
  
    }
  }

  const handleDataAvailable = (event: BlobEvent) => {
    try {
      if (event.data.size > 0) {

        if (props.onRecordingStop) {
          props.onRecordingStop(event.data);
        }

        if (props.saveVideoRecording) {

          setPendingUploads(pendingUploads + 1);

          let fileReader = new FileReader();

          fileReader.onloadend = async () => {
            const uint8ArrayNew = new Uint8Array(fileReader.result as ArrayBuffer);
            await uploadToS3(uint8ArrayNew);
          }
          fileReader.readAsArrayBuffer(event.data);
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleStopCaptureClick = async () => {

    try {

      stopCamera();

      if (!props.isDemo && props.saveVideoRecording){
        if (uploadPartNumber > 0 && props.saveVideoRecording) {
  
          while (pendingUploadsRef.current > 0) {
            setWaitingForUpload(true);
            await timeout(1000);
          }
  
          setWaitingForUpload(false);

          // All videos should be combined, but certificate is generated only at Final Steps page.
          await combineVideo(props.username + '/' + videoUniqueFolder);

          if (props.onFinished)
            props.onFinished(videoUniqueFolder);
        }
      }
    }
    catch (e) {
      console.log(JSON.stringify(e));
    }
  }

  const uploadToS3 = async (uint8ArrayNew: Uint8Array) => {
    try {
      var s3 = new AWS.S3({
        region: "eu-west-2",
        maxRetries: 5,
        retryDelayOptions: {
          base: 1000,
          customBackoff: (retryCount: number, err: Error | undefined) => {
            console.log(`retry: ${retryCount} :: ${err}`);
            return -1;
          }
        }
      });

      const currentUploadId = uploadId;

      var upload = new AWS.S3.ManagedUpload({
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        service: s3,
        params: {
          Body: uint8ArrayNew,
          Bucket: VIDEO_BUCKET,
          Key: props.username + '/' + videoUniqueFolder + '/' + currentUploadId + "-" + Date.now(),
        },
      });

      uploadId++;

      setUploadPartNumber(uploadPartNumber + 1);

      if (props.saveVideoRecording) {

        const onUploadSuccess = () => {
          setPendingUploads(pendingUploads - 1);
          // console.log('uploaded data', data);
        }


        upload = new AWS.S3.ManagedUpload({
          partSize: 10 * 1024 * 1024,
          queueSize: 1,
          service: s3,
          params: {
            Body: uint8ArrayNew,
            Bucket: VIDEO_BUCKET,
            Key: props.username + '/' + videoUniqueFolder + '/' + currentUploadId + "-" + Date.now(),
          },
        });

        tryUpload(upload, onUploadSuccess, (err: AWS.AWSError) => onUploadInterrapted(err))
      }

      return true;
    }
    catch (e) {
      console.log(JSON.stringify(e));
      if (props.onInterrupted)
        props.onInterrupted(props.username + '/' + videoUniqueFolder);
      stopCamera();
      return false;
    }
  }

  const uploadSubtitlesToS3 = async () => {
    try {
      await updateAWSCredentials();

      var srtText = "";
      if (processCuesToSave && processCuesToSave.length > 0){

      processCuesToSave.forEach((cue:any, index: number) => {
        srtText += `${index + 1}\n`;
        srtText += `${formatTime(cue.startTime)} --> ${formatTime(cue.endTime)}\n`;
        srtText += `${cue.text}\n\n`;
      });

      var s3 = new AWS.S3({
        region: "eu-west-2",
        maxRetries: 5,
        retryDelayOptions: {
          base: 1000,
          customBackoff: (retryCount: number, err: Error | undefined) => {
            console.log(`retry: ${retryCount} :: ${err}`);
            return -1;
          }
        }
      });

      var upload = new AWS.S3.ManagedUpload({
        partSize: 10 * 1024 * 1024,
        queueSize: 1,
        service: s3,
        params: {
          Body: srtText,
          Bucket: VIDEO_BUCKET,
          Key: props.username + '/' + videoUniqueFolder + '/subtitles.srt',
        },
      });

      await upload.promise()
      }
    }
    catch (e) {
      console.error('Subtitles upload error:', JSON.stringify(e));
    }
  }

  const onUploadInterrapted = (err: AWS.AWSError) => {
    console.log(JSON.stringify(err));
    if (props.onInterrupted)
      props.onInterrupted(props.username + '/' + videoUniqueFolder);
    stopCamera();
  }

  const tryUpload = (upload: AWS.S3.ManagedUpload, onSuccess: any, onError: any) => {
    upload.send(function (err, data) {
      if (err) {
        onError(err);
      } else {
        onSuccess();
      }
    });
  }

  const stopCamera = () => {
    if (mediaRecorderRef?.current && mediaRecorderRef.current.state === "recording"){
      mediaRecorderRef!.current!.stop();
    }

    if (mediaStream){
      mediaStream.getTracks().forEach(function(track) {
        track.stop();
      });
    }
  }

  const timeout = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  useEffect(() => {
    if (cues && cues.length > 0) {
      var currentTime = Date.now() / 1000; // convert to seconds as cues are in seconds.

      if (videoRecordingStartedTime === 0){
          setVideoRecordingStartedTime(currentTime);
      }

    const elapsedTime = videoRecordingStartedTime !== 0
      ? (currentTime - videoRecordingStartedTime) 
      : 0; // for the first cue offset should be 0.

      const preparedCues = cues.map(cue => {
        cue.startTime += elapsedTime;
        cue.endTime += elapsedTime;
        return cue;
      });

      setProcessCuesToSave(processCuesToSave.concat(preparedCues));
    }
    // eslint-disable-next-line
  }, [cues]);

  useEffect(() => {
    if (!props.isDemo && props.saveVideoRecording)
      uploadSubtitlesToS3();
    // eslint-disable-next-line
  }, [processCuesToSave.length]);


  const loadSubtitles = async (subtitlesUrl: string) => {
    console.log(subtitlesUrl);
    var response = await axios.get(subtitlesUrl);

    setTimeout(function () {
      const parser = new WebVTTParser();
      
      try {
        const parsedCues = parser.parse(response.data, 'metadata').cues as any[];
        setCues(parsedCues);
        console.log('cues time:', webcamPreviewRef.current?.currentTime);
      }
      catch (e) {
        console.log(e);
      }
    }, 50);
  }

  const formatTime = (timeInSeconds: number) => {
    const pad = (num: number, size: number) => ('000' + num).slice(size * -1);
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor(timeInSeconds % 3600 / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.floor((timeInSeconds - Math.floor(timeInSeconds)) * 1000);

    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
}

  useEffect(() => {
    if (props.subtitlesUrl){
      setCues([]);
      loadSubtitles(props.subtitlesUrl);
    }
  }, [props.subtitlesUrl]);

  return (
    <div>
      {props.isRecordingStarted && <RecSign />}
      <video ref={webcamPreviewRef} onPlaying={() => props.onMouted!()} id="preview" playsInline autoPlay muted
        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', transform: 'scale(-1,1)' }}>
        {/* {props.subtitlesUrl &&
          <track ref={subtitlesRef} id='subtitles-track' label="English" kind="subtitles" default />
        } */}
      </video>
      { props.subtitlesUrl && cues?.length > 0 && <MultiSubtitles cues={cues} />}
      {waitingForUpload && <div id="uploading"><CircularProgress size="10" className="uploading-circle last-spinner" /><div /></div>}
    </div>
  );
};

export default WebcamStreamCapture;