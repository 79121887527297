import validator from 'validator'
import { NewUserModel } from '../models/user';

interface ValidationErrors {
  name?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  termsAccepted?: string;
}

const validate = (user: NewUserModel) => {
  const { password, email, confirmPassword } = user;
  var validationErrors = {} as ValidationErrors;

  if (!user.name) {
    validationErrors.name = "Name is required";
  }

  if (!email)
    validationErrors.email = "Email address is required";

  if (email && !validator.isEmail(email))
    validationErrors.email = "Email address is not valid";

  const upperFormat = /[A-Z].*$/;
  const hasUpperCharater = upperFormat.test(password || "");

  if (!hasUpperCharater) {
    validationErrors.confirmPassword = "Password must contain at least 1 capital letter";
  }

  const lowerFormat = /[a-z].*$/;
  const hasLowerCharater = lowerFormat.test(password || "");

  if (!hasLowerCharater) {
    validationErrors.confirmPassword = "Password must contain at least 1 lowercase letter";
  }

  const numberFormat = /\d.*$/;
  const hasNumberCharater = numberFormat.test(password || "");

  if (!hasNumberCharater) {
    validationErrors.confirmPassword = "Password must contain at least 1 number";
  }

  if (password && password.length < 8) {
    validationErrors.confirmPassword = "Password must be at least 8 characters long";
  }

  if (!password)
    validationErrors.password = "Password is required";

  if (!confirmPassword)
    validationErrors.confirmPassword = "Passwords do not match";

  if (password !== confirmPassword)
    validationErrors.confirmPassword = "Passwords do not match";
  
  if (!user.termsAccepted)
    validationErrors.termsAccepted = "You must accept the terms and conditions";

  return validationErrors;
}

const UserDetailsService = {
  validate
}

export default UserDetailsService;