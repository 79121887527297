import React from 'react'
import { Tenant } from '../models/tenant';


const TenantContext = React.createContext<Tenant>({
    tenantId: "",
    tenantName: "",
    tutorialSteps: []as any[],
    tutorialPhoneSteps: []as any[],
    screenChecks: [] as any[],
    recordingSteps: []as any[],
    finalSteps: []as any[],
});

export const TenantProvider = TenantContext.Provider

export default TenantContext